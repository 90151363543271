import { MLoggerLevel } from '@mercadona/core/logger';
import { MPlatformEnvironment } from '@mercadona/core/platform';
import { MTelemetryConfig, MTraces } from '@mercadona/core/telemetry';
import { MEnviroment } from '@mercadona/core/utils/environment';

/**
 * Do not duplicate this file into environment.dev.ts, environment.itg.ts, ...
 * Define here ONLY localhost environment variables.
 */
/**
 * For dev,itg,pre,pro environments copy this content to the chart files
 * charts/env/values-<env>.yaml
 */
const localEnvironment = {
  production: false,
  logLevel: MLoggerLevel.TRACE,
  env: 'local' as MPlatformEnvironment,
  auth: {
    clientId: '400ea3da-890f-4ca8-a89f-29b78939f9c4',
    authority: 'https://fed.itgmercadona.com/adfs',
    knownAuthorities: ['https://fed.itgmercadona.com/adfs'],
    resource: 'api://mercadona/audience/services/portalffccse/v1',
    scope: ['openid'],
    redirectUri: 'https://localhost:4200/portalffccse',
    authOnAppStart: false,
    postLogoutRedirectUri: 'https://localhost:4200/portalffccse'
  },
  telemetryConfig: {
    url: 'https://otelcol-front.dev.gcp.mercadona.com/v1/traces',
    traces: MTraces.DEFAULT
  } as MTelemetryConfig,
  serverPortal: {
    url: 'https://portalffccse-back.dev.mercadona.com/web-main/api/v1',
    scope: ['openid'],
    resource: 'api://mercadona/audience/services/portalffccse/v1'
  }
};

const envInstance = new MEnviroment('portalffccse-front-main', localEnvironment);

export const environment = envInstance.environment;
